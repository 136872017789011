import { isArray, isObject, isString } from '@mechhive/helpers';
import { useDocumentEvent } from '@mechhive/react';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import { useState } from 'react';
import type { StoryblokDoc } from '~/api/types/storyblok/blocks/StoryblokDoc';
import { useTranslate } from '~/hooks/useTranslate';
import { StoryblokRichText } from '../storyblok/StoryblokRichText';
import { Title } from '../typograph/Title';
import { Icon, IconName } from '../ui/Icon';

type FAQProps = {
  className?: string;
  children: ReactElement<typeof FAQItem> | Array<ReactElement<typeof FAQItem>> | undefined;
}

export const FAQ = ( { className, children }: FAQProps ) => {
  const items = children != null ? isArray( children ) ? children : [ children ] : [];
  const t = useTranslate();
  if ( items.length === 0 ) {
    return null;
  }

  return (
    <div
      className={ classNames(
        'flex flex-col items-center gap-8',
        className 
      ) }
      itemScope
      itemType="https://schema.org/FAQPage">
      <Title headingLevel={ 2 }>{ t( 'FAQ_TITLE' ) }</Title>
      <div className={ 'flex flex-col gap-5 w-full' }>
        { children }
      </div>
    </div>
  )
}

type FAQItemProps = {
  title: string;
  content: string | StoryblokDoc;
}

export const FAQItem = ( { title, content }: FAQItemProps ) => {
  const [ isOpen, setIsOpen ] = useState<boolean>( false );
  
  const handleOpenClick = () => {
    document.dispatchEvent( new Event( 'closeAllFAQItems' ) );
    setIsOpen( !isOpen );
  }

  useDocumentEvent( 'closeAllFAQItems', () => {
    setIsOpen( false )
  } )

  return (
    <div
      className={ classNames(
        'flex flex-col bg-[#080A26] border-[1.5px] border-white/20 py-2.5 px-5 rounded-[10px] gap-5 w-full transition-all duration-200 hover:drop-shadow-[0px_0px_6px_rgba(90,125,251,0.40)]',
        isOpen ? 'drop-shadow-[0px_0px_6px_rgba(90,125,251,0.40)]' : ''
      ) }
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div
        className={ 'flex flex-row justify-between items-center gap-5 cursor-pointer' }
        onClick={ handleOpenClick }>
        <h3
          className={ 'font-semibold' }
          itemProp="name">
          { title }
        </h3>
        <Icon 
          className={  classNames(
            'text-white transition-transform duration-300 icon-size-6',
            isOpen ? '-rotate-180' : 'rotate-0'
          ) }
          name={ IconName.ChevronUp }
        />
      </div>
      <div 
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        className={ classNames(
          isOpen ? 'block' : 'hidden'
        ) }>
        <div itemProp="text">
          { isString( content ) &&
            content
          }
          { isObject( content ) &&
            <StoryblokRichText>
              { content } 
            </StoryblokRichText>
          }
        </div>
      </div>
    </div>
  )
}